<template>
  <div class="wrapper">
    <notifications></notifications>

    <div v-if="isMobile">
      
      <b-alert
        class="m-0 text-center"
        show
        dismissible
        style="font-size: 18px"
        v-if="
          getUserLogged.company && getUserLogged.company.company_plan.status === 'TESTING'
        "
      >
        Bem vindo a Liga ERP! Seu período de avaliação termina em
        {{ daysCompanyToTest }} dias
  
      </b-alert>
      
      <b-alert
        class="m-0 text-center"
        show
        style="font-size: 18px"
        variant="warning"
        v-if="
          getUserLogged.company &&
          getUserLogged.company.company_plan.status === 'CANCELED'
        "
      >
        Pagamento não registrado. Sua assinatura encontra-se com o status de cancelamento.
        <!-- <base-button outline size="sm" @click="() => $router.push('/faturas-assinaturas')"
          >Regularizar</base-button
        > -->
      </b-alert>
    </div>

    <side-bar
      :class="{ 'loading-skeleton': getUserLoggedRoles.length == 0 }"
      class="sub-menu-links "
      style="z-index: 99 !important"
    >
      <template slot="links">
        <div>
          <sidebar-item
            :active="isCurrentRoute('Dashboard')"
            :link="{
              name: 'Dashboard',
              path: '/dashboard',
              icon: 'ni ni-tv-2 ',
            }"
          >
          </sidebar-item>

          <hr class="m-2"  />
          <sidebar-item
            v-if="permission('sale.read')"
            class=""
            :activeClass="isCurrentRoute('Sale')"
            :link="{
              name: 'Novo pedido',
              path: '/balcao/vendas',
              icon: 'fa fa-cash-register',
            }"
          >
          </sidebar-item>

          <hr class="m-2"  />
          <a
            href="#"
            class="content-sub-menu-order ml-custom-itens pl-1 d-flex justify-content-between nav-link"
            @click="showSubMenuOrder"
            style="cursor: pointer"
          >
            <span class="">
              <i :class="iconMenuOrder" class=""></i>
              <span class="nav-link-text">{{ labelMenuOrder }}</span>
            </span>
            <i
              :class="{
                'ni ni-bold-right': !isMobile,
                'ni ni-bold-down': isMobile,
                'mr-5': isMobile,
              }"
              class="mt-1 "
              style="font-size: 13px; font-size: 13px; color: rgb(184, 184, 184);"
            ></i>
          </a>

          <div
  class="sub-menu-order d-none sub-menu-links default-bg-menu"
  :class="{ 'ml-4': isMobile }"
  @mouseleave="showSubMenuOrder"
>
  <!-- Gestão de Pedidos -->
  <!-- <sidebar-group name="Gestão de Pedidos"> -->
    <h6>Gestão de Pedidos</h6>
    <sidebar-item
      :activeClass="isCurrentRoute('Order') || isCurrentRoute('sale-create')"
      v-if="permission('order.read')"
      :link="{
        name: 'Pedidos',
        path: '/pedidos',
        icon: 'ni ni-cart',
      }"
      :style="activeMenuProduct($route.path)"
    ></sidebar-item>
    <sidebar-item
      v-if="permission('budget.read')"
      :activeClass="isCurrentRoute('budget-read')"
      :link="{
        name: 'Orçamentos',
        path: '/orcamentos',
        icon: 'ni ni-single-copy-04',
      }"
    ></sidebar-item>
    <sidebar-item
      v-if="permission('sale.read')"
      :activeClass="isCurrentRoute('Sale')"
      :link="{
        name: 'Itens do pedido',
        path: '/vendas',
        icon: 'ni ni-bag-17',
      }"
    ></sidebar-item>
  <!-- </sidebar-group> -->

  <!-- Análise e Relatórios -->
  <!-- <sidebar-group name="Análise e Relatórios"> -->
    <h6>Análise e Relatórios</h6>
    <sidebar-item
      :active="isCurrentRoute('Mapa')"
      :destaque="false"
      :link="{
        name: 'Mapa de vendas',
        path: '/mapa',
        icon: 'ni ni-map-big',
      }"
    ></sidebar-item>
  <!-- </sidebar-group> -->

  <!-- Operações e Logística -->
  <!-- <sidebar-group name="Operações e Logística"> -->
    <h6>Operações e Logística</h6>
    <sidebar-item
      v-if="permission('coleta.read')"
      :activeClass="isCurrentRoute('coleta')"
      :link="{
        name: 'Coletas',
        path: '/coletas',
        icon: 'ni ni-square-pin',
      }"
    ></sidebar-item>
    <sidebar-item
      :link="{
        name: 'Serviços',
        path: '/producoes',
        icon: 'ni ni-settings',
      }"
    ></sidebar-item>
  <!-- </sidebar-group> -->

  <!-- Representantes -->
  <!-- <sidebar-group name="Representantes"> -->
    <h6>Representantes</h6>
    <sidebar-item
      v-if="permission('representative.read')"
      :destaque="true"
      :link="{
        name: 'Representantes',
        path: '/representative',
        icon: 'ni ni-single-02',
      }"
    ></sidebar-item>
  <!-- </sidebar-group> -->
</div>


          <hr class="m-2" />
          <a
            href="#"
            class="content-sub-menu-product ml-custom-itens pl-1 d-flex justify-content-between  nav-link"
            @click="showSubMenuProduct"
            style=""
            v-if="permission('product.read')"
          >
            <span>
              <i :class="iconMenuProduct"></i>
              <span class="nav-link-text ">{{ labelMenuProduct }}</span>
            </span>
            <i
              :class="{
                'ni ni-bold-right': !isMobile,
                'ni ni-bold-down': isMobile,
                'mr-5': isMobile,
              }"
              class="mt-1"
              style="font-size: 13px; color: rgb(184, 184, 184)"
            ></i>
          </a>

          <div
              class="sub-menu-product d-none sub-menu-links"
              @mouseleave="showSubMenuProduct"
              :class="{ 'ml-4': isMobile }"
            >
              <!-- Grupo: Gestão de Produtos -->
              <h6 class="menu-section-title">Gestão de Produtos</h6>
              <sidebar-item
                v-if="permission('product.read')"
                :activeClass="isCurrentRoute('product-create')"
                :link="{ name: 'Produtos', path: '/produtos', icon: 'ni ni-box-2 ' }"
              ></sidebar-item>
              <sidebar-item
                v-if="permission('product.read')"
                :activeClass="isCurrentRoute('product-compras')"
                :link="{
                  name: 'Categorias',
                  path: '/categoria',
                  icon: 'ni ni-tag',
                }"
              ></sidebar-item>
              <sidebar-item
                v-if="permission('product.read')"
                :activeClass="isCurrentRoute('product-compras')"
                :link="{
                  name: 'Subcategorias',
                  path: '/subcategoria',
                  icon: 'ni ni-bullet-list-67',
                }"
              ></sidebar-item>

              <!-- Grupo: Operações -->
              <h6 class="menu-section-title">Operações</h6>
              <sidebar-item
                v-if="permission('product.read')"
                :activeClass="isCurrentRoute('productEstoque-read')"
                :link="{
                  name: 'Estoques',
                  path: '/produtos/estoques',
                  icon: 'ni ni-vector ',
                }"
              ></sidebar-item>
              <sidebar-item
                v-if="permission('product.read')"
                :activeClass="isCurrentRoute('productEntrada-read')"
                :link="{
                  name: 'Movimentação de estoque',
                  path: '/produtos/movimentacao',
                  icon: 'ni ni-app ',
                }"
              ></sidebar-item>

              <!-- Grupo: Marketing -->
              <h6 class="menu-section-title">Marketing</h6>
              <sidebar-item
                v-if="permission('loja.read')"
                :destaque="false"
                :activeClass="isCurrentRoute('reconquiste')"
                :link="{
                  name: 'Catálogo Digital',
                  path: '/loja',
                  icon: 'ni ni-spaceship',
                }"
              ></sidebar-item>
            </div>



          <hr class="m-2" />

          <!-- Clientes -->
          <a
            href="#"
            class="content-sub-menu-client ml-custom-itens pl-1 d-flex justify-content-between  nav-link"
            @click="showSubMenuClient"
            style=""
            v-if="permission('customer.read')"
          >
            <span>
              <i class="ni ni-favourite-28 "></i>
              <span class="nav-link-text ">Clientes</span>
            </span>
            <i
              :class="{
                'ni ni-bold-right': !isMobile,
                'ni ni-bold-down': isMobile,
                'mr-5': isMobile,
              }"
              class="mt-1"
              style="font-size: 13px; color: rgb(184, 184, 184)"
            ></i>
          </a>

          <div
            class="sub-menu-client d-none sub-menu-links"
            @mouseleave="showSubMenuClient"
            :class="{ 'ml-4': isMobile }"
          >

          <sidebar-item
            v-if="permission('customer.read')"
            :activeClass="isCurrentRoute('Customer') || isCurrentRoute('Customer-create')"
            :link="{
              name: 'Clientes',
              path: '/clientes',
              icon: 'ni ni-favourite-28',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="permission('customer.read')"
            :destaque="false"
            :activeClass="isCurrentRoute('reconquiste')"
            :link="{
              name: 'A Recuperar',
              path: '/reconquiste',
              icon: 'ni ni-time-alarm',
            }"
          >
          </sidebar-item>

          </div>

          <hr class="m-2" />
          <a
            href="#"
            class="content-sub-menu-order ml-custom-itens pl-1 d-flex justify-content-between nav-link"
            @click="showSubMenuMovement"
            style="cursor: pointer"
            v-if="permission('movements.read')"
          >
            <span>
              <i :class="iconMenuMovement" class=""></i>
              <span class="nav-link-text ">{{ labelMenuMovement }}</span>
            </span>
            <i
              :class="{
                'ni ni-bold-right': !isMobile,
                'ni ni-bold-down': isMobile,
                'mr-5': isMobile,
              }"
              class="mt-1 "
              style="font-size: 13px; color: rgb(184, 184, 184)"
            ></i>
          </a>

          <div
            class="sub-menu-movement d-none sub-menu-links default-bg-menu"
            :class="{ 'ml-4': isMobile }"
            @mouseleave="showSubMenuMovement"
          >

            <sidebar-item
              v-if="permission('movementsreport.read')"
              :link="{
                name: 'Relatório de Movimentações',
                path: '/relatorio/movimentacoes',
                icon: 'ni ni-chart-pie-35',
              }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="permission('movements.read')"
              :link="{
                name: 'Movimentações',
                path: '/movimentacoes',
                icon: 'ni ni-sound-wave ',
              }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="permission('movements.read')"
              :link="{
                name: 'Contas a pagar',
                path: '/contas-a-pagar',
                icon: 'ni ni-credit-card',
              }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="permission('movements.read')"
              :link="{
                name: 'Contas a receber',
                path: '/contas-a-receber',
                icon: 'ni ni-money-coins',
              }"
            >
            </sidebar-item>

            <!-- <sidebar-item
              v-if="permission('movements.read')"
              :link="{
                name: 'Contras bancárias',
                path: '/conta-bancaria',
                icon: 'ni ni-building',
              }"
            >
            </sidebar-item> -->
            <!-- <sidebar-item v-if="permission('movement.dre')" :link="{
                name: 'Conta bancária',
                path: '/plano-de-contas',
                icon: 'ni ni-building',
              }">
              </sidebar-item> -->
            <sidebar-item
              v-if="permission('movement.dre')"
              :link="{
                name: 'Plano de contas',
                path: '/plano-de-contas',
                icon: 'ni ni-vector',
              }"
            >
            </sidebar-item>
            <!-- <sidebar-item v-if="permission('costCenter.read')" :activeClass="isCurrentRoute('cost-center-read')"
                :link="{ name: 'Centro de custos', path: '/centro-de-custo', icon: 'ni ni-align-center' }">
              </sidebar-item> -->
          </div>

          <hr class="m-2" />
          <sidebar-item
            v-if="permission('report.read')"
            :activeClass="isCurrentRoute('Sale')"
            :link="{
              name: 'Relatorios',
              path: '/relatorios',
              icon: 'ni ni-chart-bar-32',
            }"
          >
          </sidebar-item>
       
          <hr class="m-2" />
          <a
            href="#"
            class="content-sub-menu-product ml-custom-itens pl-1 d-flex justify-content-between nav-link"
            @click="showSubMenuVerMais"
            style=""
            v-if="
              permission('seller.read') ||
              permission('provider.read') ||
              permission('user.read')
            "
          >
            <span class="">
              <i class="ni ni-bullet-list-67 "></i>
              Ver mais
            </span>
            <i
              :class="{
                'ni ni-bold-right': !isMobile,
                'ni ni-bold-down': isMobile,
                'mr-5': isMobile,
              }"
              class="mt-1 "
              style="font-size: 13px; color: rgb(184, 184, 184)"
            ></i>
          </a>

          <div
            class="sub-menu-ver-mais sub-menu-links d-none default-bg-menu"
            @mouseleave="showSubMenuVerMais"
            :class="{ 'ml-4': isMobile }"
          >

            <sidebar-item
              v-if="permission('frete.read')"
              :activeClass="isCurrentRoute('frete.read')"
              :link="{
                name: 'Meus envios',
                path: '/envios',
                icon: 'ni ni-delivery-fast ',
              }"
            >
            </sidebar-item>

            <sidebar-item
              v-if="permission('taxNote.read')"
              :activeClass="isCurrentRoute('Sale')"
              :link="{
                name: 'Notas fiscais',
                path: '/notas',
                icon: 'ni ni-single-copy-04',
              }"
            >
            </sidebar-item>

            <sidebar-item
              v-if="permission('seller.read')"
              :activeClass="isCurrentRoute('Seller')"
              :link="{
                name: 'Vendedores',
                path: '/vendedores',
                icon: 'ni ni-diamond',
              }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="permission('provider.read')"
              :activeClass="
                isCurrentRoute('Provider') || isCurrentRoute('Provider-create')
              "
              :link="{
                name: 'Fornecedores',
                path: '/fornecedor',
                icon: 'ni ni-shop ',
              }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="permission('user.read')"
              :activeClass="isCurrentRoute('User') || isCurrentRoute('user-create')"
              :link="{
                name: 'Usuários',
                path: '/usuarios',
                icon: 'ni ni-single-02',
              }"
            >
            </sidebar-item>
          </div>
        </div>

      </template>
    </side-bar>

    <div class="main-content">
      <dashboard-navbar
        :type="$route.meta.navbarType"
        v-if="!isMobile"
      ></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="300" origin="center top" mode="out-in">
          <div class="p-3">
            <router-view class="show-page"></router-view>
          </div>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<style scoped>
.content-sub-menu-order,
.content-sub-menu-product,
.content-sub-menu-client  {
  color: rgba(0, 0, 0, 0.5);
}

.sub-menu-links {
  /* padding: 0.5rem 0;
    margin: 0.125rem 0 0; */
  font-size: 1rem;
  color: #525f7f;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 0 solid rgba(0, 0, 0, 0.15); */
  border-radius: 0.4375rem;
  -webkit-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15),
    0 5px 15px rgba(0, 0, 0, 0.1);
  opacity: 1;
  pointer-events: auto;
  -webkit-animation: show-navbar-dropdown 0.25s ease forwards;
  animation: show-navbar-dropdown 0.25s ease forwards;
}

/* .nav-link > i { */
/* color: rgba(0, 0, 0, 0.5) !important; */
/* } */

.navbar-nav > a,
.navbar-nav > div {
  margin-bottom: 12px;
}

.sidebar-item {
  margin-bottom: 20px importante; /* Adjust the margin-bottom as needed */
}

.content-sub-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff; /* Adjust the background color if needed */
  box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.1); /* Add a shadow if desired */
}

/* Mobile */
@media only screen and (max-width: 500px) {
  .content-sub-menu.fixed-bottom {
    position: fixed;
    bottom: 0;
  }
}
</style>

<style>
/* .content-page>div {
  overflow-y: auto !important;
} */

.content-sub-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ml-custom-itens {
  margin-left: 1.2rem !important;
  /* color: gray; */
}

/* Mobile */
@media only screen and (max-width: 500px) {
  .ml-custom-itens {
    margin-left: 0.8rem !important;
    /* color: gray; */
  }
}

.ml-custom-itens:hover {
  color: #565555;
}

.content-sub-menu-product, .content-sub-menu-client  {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}
</style>

<style scoped>
i {
  min-width: 2.25rem;
}
</style>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import moment from "moment";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import { FadeTransition } from "vue2-transitions";
import { mapGetters } from "vuex";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition,
  },
  computed: {
    ...mapGetters(["permission", "isMobile", "getUserLoggedRoles", "getUserLogged"]),
  },
  data() {
    return {
      daysCompanyToTest: 0,
      labelMenuProduct: "Produtos",
      iconMenuProduct: "ni ni-box-2 ",
      labelMenuOrder: "Gestão de vendas",
      iconMenuOrder: "ni ni-cart ",
      iconMenuMovement: "ni ni-sound-wave",
      labelMenuMovement: "Financeiro",
      interval: null
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    isCurrentRoute(routeName) {
      return this.$route.name == routeName;
    },
    showSubMenu() {
      document.getElementsByClassName("sub-menu-ver-mais")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-order")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-product")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-client")[0].classList.add("d-none");
      
      if (
        document.getElementsByClassName("sub-menu")[0].classList.value.includes("d-none")
      ) {
        document.getElementsByClassName("sub-menu")[0].classList.remove("d-none");
      } else {
        document.getElementsByClassName("sub-menu")[0].classList.add("d-none");
      }
    },
    showSubMenuVerMais() {
      document.getElementsByClassName("sub-menu-order")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-product")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-movement")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-client")[0].classList.add("d-none");

      // document.getElementsByClassName('sub-menu')[0].classList.add('d-none')
      if (
        document
          .getElementsByClassName("sub-menu-ver-mais")[0]
          .classList.value.includes("d-none")
      ) {
        document
          .getElementsByClassName("sub-menu-ver-mais")[0]
          .classList.remove("d-none");
      } else {
        document.getElementsByClassName("sub-menu-ver-mais")[0].classList.add("d-none");
      }
    },
    showSubMenuProduct() {
      document.getElementsByClassName("sub-menu-order")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-ver-mais")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-movement")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-client")[0].classList.add("d-none");

      if (
        document
          .getElementsByClassName("sub-menu-product")[0]
          .classList.value.includes("d-none")
      ) {
        document.getElementsByClassName("sub-menu-product")[0].classList.remove("d-none");
      } else {
        document.getElementsByClassName("sub-menu-product")[0].classList.add("d-none");
      }
    },
    showSubMenuMovement() {
      document.getElementsByClassName("sub-menu-product")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-order")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-ver-mais")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-client")[0].classList.add("d-none");

      if (
        document
          .getElementsByClassName("sub-menu-movement")[0]
          .classList.value.includes("d-none")
      ) {
        document
          .getElementsByClassName("sub-menu-movement")[0]
          .classList.remove("d-none");
      } else {
        document.getElementsByClassName("sub-menu-movement")[0].classList.add("d-none");
      }
    },
    showSubMenuOrder() {
      document.getElementsByClassName("sub-menu-product")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-movement")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-ver-mais")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-client")[0].classList.add("d-none");

      if (
        document
          .getElementsByClassName("sub-menu-order")[0]
          .classList.value.includes("d-none")
      ) {
        document.getElementsByClassName("sub-menu-order")[0].classList.remove("d-none");
      } else {
        document.getElementsByClassName("sub-menu-order")[0].classList.add("d-none");
      }
    },
    showSubMenuClient() {
      document.getElementsByClassName("sub-menu-product")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-movement")[0].classList.add("d-none");
      document.getElementsByClassName("sub-menu-ver-mais")[0].classList.add("d-none");

      if (
        document
          .getElementsByClassName("sub-menu-client")[0]
          .classList.value.includes("d-none")
      ) {
        document.getElementsByClassName("sub-menu-client")[0].classList.remove("d-none");
      } else {
        document.getElementsByClassName("sub-menu-client")[0].classList.add("d-none");
      }
    },
    activeMenuProduct(path) {
      if (typeof path === "string" && path.includes("/produto")) {
      }
    },
    activeMenuOrder(path) {
      if (
        typeof path === "string" &&
        (path.includes("/coletas") ||
          path.includes("/pedidos") ||
          path.includes("/orcamentos"))
      ) {
        if (path.includes("coletas")) {
          this.labelMenuOrder = "Coletas";
          this.iconMenuOrder = "ni ni-square-pin ";
        } else if (path.includes("orcamentos")) {
          this.labelMenuOrder = "Orçamentos";
          this.iconMenuOrder = "ni ni-single-copy-04 ";
        } else {
          this.labelMenuOrder = "Pedidos";
          this.iconMenuOrder = "ni ni-cart ";
        }

        return { color: "#1da1f2" };
      } else {
        this.labelMenuOrder = "Pedidos";
        this.iconMenuOrder = "ni ni-cart ";
      }
    },
    activeMenuMovement(path) {
      if (
        typeof path === "string" &&
        (path.includes("/movimentacoes") || path.includes("/centro-de-custo"))
      ) {
        if (path.includes("movimentacoes")) {
          this.labelMenuMovement = "Movimentações";
          this.iconMenuMovement = "ni ni-sound-wave";
        } else {
          this.labelMenuMovement = "Centro de custos";
          this.iconMenuMovement = "ni ni-money-coins";
        }

        return { color: "#1da1f2" };
      }
    },
  },

  mounted() {
    this.initScrollbar();

    if (document.getElementById("crisp_id")) {
      document.getElementById("crisp_id").remove();
    }

    this.interval = setInterval(() => {
      if (this.getUserLogged.id) {
        
        clearInterval(this.interval)

        // Init crisp
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "f0023615-5d91-475e-b5ba-1f0e8bd5dc3f";
        (function () {
          let d = document;
          let s = d.createElement("script");
          s.src = "https://client.crisp.chat/l.js";
          s.id = "crisp_id";
          s.async = 1;
          d.getElementsByTagName("head")[0].appendChild(s);
        })();

        $crisp.push(["set", "session:segments", [["Usuário"]]]);
        // $crisp.push(["do", "session:reset"]);
        $crisp.push(["set", "user:nickname", [this.getUserLogged.name]]);
        $crisp.push(["set", "user:email", [this.getUserLogged.email]]);
        $crisp.push(["set", "user:phone", [this.getUserLogged.phone]]);

        setTimeout(() => {

        if (this.getUserLogged.company) {
          const testDateStart = moment();
          const testDateEnd = moment(
            this.getUserLogged.company.company_plan.test_date_end
          );
          this.daysCompanyToTest = Math.abs(testDateEnd.diff(testDateStart, "days"));
        }    }, 1000)


        

      }
    }, 1000);
  },
  created() {},
};
</script>
