import Client from '@/services/Client'
const axios = new Client()

export const storeConfiguration = {
  state: {
  },
  getters: {
  },
  mutations: { 
  },
  actions: {
    listStoreConfiguration({commit}, url) {
      return new Promise((resolve, reject) => {
        let uri = 'store-configuration'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
        .finally(()=>{
        })
      })
    },
    updateStoreConfiguration({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.pust('store-configuration/'+data.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    updateOrCrateStoreConfiguration({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post('store-configuration', data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    }
  }
}
