import Client from '@/services/Client'
const axios = new Client()

export const representative = {
  state: {
   
  },
  getters: {
    
  },
  mutations: {
   
  },
  actions: {
    representatives({commit, getters}, url) {
      return new Promise((resolve, reject) => {
        let uri = 'representatives'
        let query = new URLSearchParams(url).toString();
        uri += query ? '?'+query : '';

        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    postRepresentatives({commit, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'representatives'
        axios.post(uri, data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    updateRepresentatives({commit, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'representatives/'+data.id
        axios.put(uri, data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
  }
}
