<template>
  <router-view> </router-view>
</template>

<script>
export default {
  async created() {
    await this.$store.dispatch("me");
  },
  mounted() {},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>

.page-item.active .page-link {
  z-index: -1;
}


/* Estiliza toda a barra de rolagem (inclui trilho e alça) */
::-webkit-scrollbar {
  width: 10px; /* Largura da barra de rolagem */
  background-color: #767676; /* Cor de fundo da barra */
}

/* Estiliza a parte traseira da barra de rolagem (trilho) */
::-webkit-scrollbar-track {
  background-color: #dcdbdb; /* Cor de fundo do trilho */
  border-radius: 10px; /* Arredondamento das bordas do trilho */
  box-shadow: inset 0 0 5px #e3eaf6; /* Sombra interna no trilho */
}

/* Estiliza a alça da barra de rolagem (parte que você arrasta) */
::-webkit-scrollbar-thumb {
  background-color: #fefefe; /* Cor da alça */
  border-radius: 10px; /* Arredondamento das bordas da alça */
  border: 2px solid transparent; /* Borda para evitar sobreposição ao trilho */
  background-clip: content-box; /* Evita que a cor de fundo se estenda além da borda */
}

/* Estiliza a alça da barra de rolagem ao passar o mouse */
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb9b9; /* Cor da alça ao passar o mouse */
}
</style>

<style>
.nav-link.active {
  background-color: rgba(235, 240, 249, 0.2) !important;
}

.nav-link.active > i,
.nav-link.active > span {
  color: #4396f4 !important;
}

.nav-link.router-link-exact-active.active,
.nav-item.active {
  background-color: rgba(235, 240, 249, 0.2) !important;
  font-weight: 700;
}

.default-bg-menu {
  /* background: #172b4d !important; */
}

#sidenav-main{
  z-index: 99 !important;
}

#sidenav-main > .nav-link {
  color: #fff !important;
}

router-link-exact-active > a > span {
  color: #5e72e4 !important;
}

.text-amount-exit {
  color: #fd4a4a;
  font-weight: 600;
  font-size: 0.875rem !important;
}

.text-amount {
  color: green;
  font-weight: 600;
  font-size: 0.875rem !important;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
  text-align: left;
}

body {
  /* background-color: #ffffff64 !important; */
  background-color: #fdfdfd;
}

.borderless-table {
  border: none !important; /* ou border: 0; */
}

table.border {
  border: 0 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.01);
}

.custom-table {
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  border: none;
}

/* Mobile */
@media only screen and (max-width: 500px) {
  .mx-datepicker-range {
    /* width: 168px !important */
  }

  .tab-content {
    display: contents !important;
  }

  .content-page > div {
    height: 85vh !important;
  }

  * {
    font-size: 14px !important;
  }
}

.content-page > div {
  /* overflow: auto; */
}

/* Desktop */
@media only screen and (min-width: 500px) {
  .sub-menu,
  .sub-menu-product,
  .sub-menu-client,
  .sub-menu-order,
  .sub-menu-movement,
  .sub-menu-ver-mais {
    width: 280px;
    -webkit-box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
    box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    background: #fff;
    padding: 15px;
    position: fixed;
    left: 248px;
    z-index: 9999 !important;
    -webkit-animation: showLeft 0.3s forwards;
    animation: showLeft 0.3s forwards;
    top: 496px;
    color: rgba(0, 0, 0, 0.5);
  }

  .sub-menu-ver-mais {
    top: 350px;
  }

  .sub-menu-product {
    top: 200px;
  }
  
  .sub-menu-client {
    top: 345px;
  }
  .sub-menu-movement {
    top: 253px;
  }
  .sub-menu-order {
    top: 210px;
  }

  .ssr-carousel-track {
    justify-content: center !important;
  }

  .type-date {
    margin-left: -41px;
  }
  .content-date {
    margin-right: 20px !important;
  }

  .content-page > div {
    height: 100vh !important;
  }
}

.content-index > .card-body {
  padding: 0 !important;
}

.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton span,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .form-control {
  color: transparent;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;
}

.badge-pill {
  font-size: 12px;
}

[id*="___BV_modal_outer_"] {
  z-index: 9999999 !important;
}

[class^="content-status-"],
[class^="content-title-status"] {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: block;
  /* position: absolute; */
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.content-status-CANCELED,
.content-status-order-PENDENCY,
.content-status-order-CANCELED,
.content-status-tax-note-CANCELED {
  background: red;
}

.content-status-IN_PROGRESS,
.content-status-awaiting_payment,
.content-status-order-NEW_ORDER,
.content-status-tax-note-AWAITING_ISSUANCE,
.content-status-order-NEW_SALE {
  background: #ffa837;
}

.content-status-CONFIRMED,
.content-status-paid,
.content-status-order-FINISHED,
.content-status-tax-note-ISSUED,
.content-status-order-CONFIRMED {
  background: #1ecf1e;
}

.content-status-OPENED,
.content-status-order-SENT,
.content-status-order-DEVOLUTION,
.content-status-DEVOLUTION {
  background: black;
}

.content-status-order-COLLECTING {
  background: #282be9 !important;
}

.content-status-order-PACKED,
.content-status-REPLACEMENT {
  background: #e9e628;
}

.content-status-refused,
.content-status-pending_refund,
.content-status-chargedback,
.content-status-refunded,
.content-status-canceled {
  background: #d43a13;
}

.content-title-status-awaiting_payment,
.content-status-waiting_payment {
  background-color: #ffcb37;
}

.content-title-status-paid {
  background: #1ecf1e;
}

.content-status-order-SENT {
  background: #282be9;
}

.content-title-status-refused,
.content-title-status-pending_refund,
.content-title-status-chargedback,
.content-title-status-canceled,
.content-title-status-refunded {
  background: #d43a13;
}

.content-title-status-refused,
.content-title-status-pending_refund,
.content-title-status-chargedback,
.content-title-status-refunded,
.content-title-status-paid,
.content-title-status-awaiting_payment {
  font-weight: 600;
}

.content-button {
  justify-content: end;
}

.content-icon-name {
  background-color: #125797;
  border-radius: 50px;
  width: 24px;
  height: 9px;
  float: left;
  margin-top: 2.3rem;
  position: relative;
  left: 1.2rem;
}

.title-header {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

@media only screen and (max-width: 500px) {
  .content-icon-name {
    margin-top: 1.2rem;
    width: 18px;
    height: 5px;
  }
}
</style>
<style>
div.cell:has(> div.dropdown) {
  /* styles serão aplicados ao "div.pannels" elemento que tenham um elemento filho "div.bg1"*/
  display: contents;
}
/* nav-link router-link-exact-active active */
.nav-link.router-link-exact-active.active > span,
.nav-link.router-link-exact-active.active > i {
  color: #4396f4 !important;
}

.nav-link.active {
  /* background-color: #fcfdfe !important; */
  color: #4396f4 !important;
  /* font-weight: 600; */
}

thead > tr > th {
  font-weight: 400 !important;
  font-size: 14px !important;
}

thead > tr > th > div {
  color: #000000;
  font-weight: 700;
}

.auth-header {
  background: linear-gradient(270deg, #5e9ce4 0.66%, #0b4983 100%) !important;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem) !important;
}
</style>

<style lang="scss">
// Bootstrap Loading Skeleton
%loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;

  &::placeholder {
    color: transparent;
  }
}
@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img p,
  li,
  .btn,
  label,
  .form-control {
    @extend %loading-skeleton;
  }
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #22a6b3;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.spinner-sm {
  width: 25px !important;
  height: 25px !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Defina a animação de pulsação */
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  80% {
    transform: scale(1);
  }
}

.item-pulse {
  animation: pulse 2s infinite; /* 2s duração, infinito loop */
}

/* Aplicar a animação ao botão quando estiver em um estado de foco ou hover */
/* .button:hover,
.button:focus { */
.item-pulse {
  animation: pulse 1s infinite;
}

.item-pulse:hover {
  animation: pulse 1.5s paused;
}
</style>
