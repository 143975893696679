import Client from '@/services/Client'
const axios = new Client()

export const movement = {
  state: {
    walletTotal: [],
    wallets: [],
    walletsSeller:[],
    costCenter:[],
    typeWallets: {
      'SALE':'Venda',
      'COMMISSION_PAID': 'Pagamento de comissåo',
      'COMMISSION': 'Comissão',
      'PROVIDER': 'Fornecedor',
      'PROVIDER_PAID': 'Pagamento de Fornecedor',
      'FRETE': 'Frete',
      'FRETE_PAID': 'Pagamento de frete',
    },
    typeMovimentPeoples: {
      'SELLER':'Vendedor',
      'CUSTOMER': 'Cliente',
      'PROVIDER': 'Fornecedor',
      'TRANSPORTADORA': 'Transportadora',
      'SERVICE': 'Serviço',
      'REPRESENTATIVE': 'Representante'
    },
    statusWallets: {
      'waiting_payment': 'Aguardando pagamento',
      'paid':'Pago',
      'canceld': 'Cancelado',
    },
    walletsSelected:[],
    filterWallet:{},
    movementTotalToMovement: {}
  },
  getters: {
    getWallets: state => state.wallets,
    getTypeWallets: state => state.typeWallets,
    getStatusWallets: state => state.statusWallets,
    getWalletsSeller: state => state.walletsSeller,
    getWalletTotal: state => state.walletTotal,
    getWalletTotal: state => state.walletTotal,
    getFilterWallet: state => state.filterWallet,
    getCostCenter: state => state.costCenter,
    getTypeMovimentPeoples: state => state.typeMovimentPeoples,
    getMovementToMovementTotal: state => state.movementTotalToMovement,
  },
  mutations: {
    setWallets: (state, value) => state.wallets = value,
    setWalletsSeller: (state, value) => state.walletsSeller = value,
    setWalletTotal: (state, value) => state.walletTotal = value,
    setFilterWallet: (state, value) => state.filterWallet = value,
    setCostCenter: (state, value) => state.costCenter = value,
    setMovementToMovementTotal: (state, value) => state.movementTotalToMovement = value,
   
  },
  actions: {
    listWallet({ commit, dispatch }, url) {
      return new Promise((resolve, reject) => {
        commit('setShowSkeletonTable', true)
        
        let uri = 'movement'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
      
        axios.get(uri)
          .then(res => {

            if(url && url.selected_itens){
              return resolve(res)
            }
             
            res.data.data.forEach(item => {
              if(item.status === 'PAID'){
                item.checked = true

              }
              else{
                item.checked = false
              }
            });

            commit('setWallets', res)
            commit('setShowSkeletonTable', false)
            
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    showMovement({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios.get('movement/show/'+id)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    liberationConfirmed({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios.post('movement/liberation-confirmed', data)
          .then(res => {
            resolve(res)
            dispatch('getWalletTotalToHeader')
          })
          .catch(err => reject(err))
      })
    },
    movementUpdate({ dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        axios.put('movement/'+data.id, data)
          .then(res => {
            resolve(res)
            if(!data.no_load_list){
              dispatch('getWalletTotalToHeader', {type: data.type})
              dispatch('listWallet', {...getters.getFilterWallet, type: data.type})
            }
          })
          .catch(err => reject(err))
      })
    },
    movementConfirmPayment({ dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        axios.put('movement/confirm/'+data.id, data)
          .then(res => {
            resolve(res)

            if(data.is_movement_order){
              dispatch('listWallet', {...getters.getFilterWallet, order_id: data.order_id})
            }else{
              dispatch('getWalletTotalToHeader', {type: data.type})
              dispatch('listWallet', {...getters.getFilterWallet, type: data.type})
            }

          })
          .catch(err => reject(err))
      })
    },
    movementCancelPayment({ dispatch, getters }, data) {
      return new Promise((resolve, reject) => {
        axios.put('movement/cancel/'+data.id, data)
          .then(res => {
            resolve(res)
            if(!data.no_load_list){
              dispatch('getWalletTotalToHeader')
            }
          })
          .catch(err => reject(err))
      })
    },

    getWalletTotalToHeader({ commit, getters }, url) {
      return new Promise((resolve, reject) => {
        if(!url && getters.getFilterWallet){
          url = getters.getFilterWallet
        }
        let uri = 'movement/total-header'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },

    getWalletTotalToHeaderMovementCaixa({ commit, getters }, url) {
      return new Promise((resolve, reject) => {
        if(!url && getters.getFilterWallet){
          url = getters.getFilterWallet
        }
        if(url.type == 'undefined'){
          url.type = ''
        }
        let uri = 'movement/total-to-header/movement/caixa'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri)
          .then(res => {
            commit('setMovementToMovementTotal', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    
    exportMovement({ commit, getters }, url) {
      return new Promise((resolve, reject) => {
        if(getters.getFilterWallet){
          url = Object.assign(url,getters.getFilterWallet)
        }

        if(url.type == 'undefined'){
          url.type = ''
        }
        let uri = 'movement/export'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri, [], false)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    
    getWalletTotalToHeaderMovement({ commit, getters }, url) {
      return new Promise((resolve, reject) => {
        if(!url && getters.getFilterWallet){
          url = getters.getFilterWallet
        }

        let uri = 'movement/total-to-header/movement'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri)
          .then(res => {
            commit('setMovementToMovementTotal', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    listWalletSeller({ commit, state }, url) {
      return new Promise((resolve, reject) => {
        commit('setShowSkeletonTable', true)
        
        let uri = 'movement'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
      
        axios.get(uri)
          .then(res => {
         
            if(url && url.selected_itens){
              return resolve(res)
            }
            
            res.data.data.forEach(item => {
              if(item.status === 'PAID'){
                item.checked = true

              }
              else{
                item.checked = false
              }
            });
  
            commit('setWalletsSeller', res)
            commit('setShowSkeletonTable', false)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    setFilterWallet({ commit, dispatch }, data){
      commit('setFilterWallet', data)
    },
    createWallet({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        let uri = 'movement'
      
        axios.post(uri, data)
          .then(res => {
            if(!data.no_load_list){
              dispatch("listWallet", {type: data.type});
            }
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    createMovementNotLoadList({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        let uri = 'movement'
        axios.post(uri, data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    createCostCenter({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        let uri = 'cost_centers'
      
        axios.post(uri, data)
          .then(res => {
            dispatch("listCostCenter", data);
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    listCostCenter({ commit, dispatch }, data) {
      commit('setShowSkeletonTable', true)
      return new Promise((resolve, reject) => {
        let uri = 'cost_centers'
        if(data){
          let query = new URLSearchParams(data).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            commit("setCostCenter", res.data);
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(() =>{
            commit('setShowSkeletonTable', false)
          })
      })
    },
    balanceOrder({ commit, dispatch }, orderId) {
      return new Promise((resolve, reject) => {
        let uri = 'movement/transactions/order/paid/'+orderId

        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(() =>{
            commit('setShowSkeletonTable', false)
          })
      })
    },
  }
}
