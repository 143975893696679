import Client from '@/services/Client'

const axios = new Client();

export const ProductEstoque = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
   
    createMovementStock({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('product/stock', data)
          .then(res => {
            if (res.data) {
              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    }

  }
}
